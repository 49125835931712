window.addEventListener('DOMContentLoaded', () => {
  const offlineStatus = document.querySelector('[data-offline-status]');

  if (offlineStatus) {
    const handleOfflineStatus = () => {
      const method = navigator.onLine ? 'remove' : 'add';
      offlineStatus.classList[method]('is-active');
    };

    window.addEventListener('online', handleOfflineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    handleOfflineStatus();
  }
});
