const CONFIG_ID = 'config';

export const parseConfig = (externalId = CONFIG_ID) => {
  const element = document.querySelector(`#${externalId}`);

  if (!element) {
    return {};
  }

  try {
    const json = JSON.parse(element.innerHTML);
    return json;
  } catch (e) {
    return {};
  }
};

export const setConfig = (newConfig) => {
  const configStr = JSON.stringify({
    ...parseConfig(),
    ...newConfig
  });

  const element = document.querySelector(`#${CONFIG_ID}`);

  if (element) {
    element.innerHTML = configStr;
  } else {
    const el = document.createElement('script');
    el.setAttribute('id', CONFIG_ID);
    el.setAttribute('type', 'application/json');
    el.innerHTML = configStr;
    document.appendChild(el);
  }
};
