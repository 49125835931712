import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getLocations    : '/v1/locations/',
  searchLocations : '/v1/locations/search/',
  getChecks       : '/v1/profile/checks/',

  phoneVerificationCall         : (token) => `/v2/internal/voximplant/code/call/?g-recaptcha-response=${token}`,
  validatePhoneVerificationCall : '/v2/internal/voximplant/code/confirm/',

  sendCallSelection: '/v1/landing-p1/sendCall/'
};

const configFormData = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default class CommonModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getLocations(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getLocations, { params }).then(({ data }) => {
        resolve(data.data);
      }).catch(reject);
    });
  }

  searchLocations(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.searchLocations, { params }).then(({ data }) => {
        resolve(data.data);
      }).catch(reject);
    });
  }

  phoneVerificationCall(token, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.phoneVerificationCall(token), params).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  validatePhoneVerificationCall(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.validatePhoneVerificationCall, params, configFormData).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getChecks() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getChecks).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  sendCallSelection(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendCallSelection, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
