const COOKIE_PROMPT_KEY = 'cookie-prompt-accepted';

const promptClose = (event) => {
  event.preventDefault();

  localStorage.setItem(COOKIE_PROMPT_KEY, true);

  const prompt = document.querySelector('[data-cookie-prompt=""]');

  if (!prompt) {
    return;
  }

  prompt.parentNode.removeChild(prompt);
};

document.addEventListener('DOMContentLoaded', () => {
  if (localStorage.getItem(COOKIE_PROMPT_KEY)) {
    return;
  }

  const prompt = document.querySelector('[data-cookie-prompt=""]');

  if (!prompt) {
    return;
  }

  prompt.style.display = 'block';

  const promptCloseButton = prompt.querySelectorAll('[data-cookie-prompt="close-button"]');

  if (!promptCloseButton || (promptCloseButton && !promptCloseButton.length)) {
    return;
  }

  promptCloseButton.forEach((btn) => {
    btn.addEventListener('click', promptClose);
  });
});
