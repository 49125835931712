import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getProductTrial  : (type) => `/v1/trial/products/${type}/`,
  createTrialOrder : '/v2/internal/order/trial/',
  checkTrialOrder  : (type) => `/v2/internal/order/trial/actions/${type}/rules/check/`
};

export default class Samples extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getProductTrial(type) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getProductTrial(type)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  // Заказ пробников
  createTrialOrder(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.createTrialOrder, params).then(({ data }) => {
        if (data && data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Возможен ли заказ пробников
  checkTrialOrder(type) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.checkTrialOrder(type)).then(({ data }) => {
        if (data && data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
