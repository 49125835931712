import MainApiModule from './modules/MainApiModule';

export const mainApi = new MainApiModule({
  baseURL : '/',
  apiURL  : '/api'
});

export default {
  mainApi
};
