// City Picker Popover
const cityPickerPopover = document.querySelector('#city-picker-ui');
let loadedCityPicker = false;
// Загрузка компонента выбора города
const startLoadCityPicker = () => {
  if (!cityPickerPopover || loadedCityPicker) {
    return;
  }

  import(
    /* webpackChunkName: "city-picker-ui" */
    '../../components/vue/CityPickerUI/index'
  ).then((partial) => partial.default(cityPickerPopover));

  loadedCityPicker = true;
};

const dataTag = document.querySelector('#data-config-city-picker-ui');
const contentCityPickerUI = document.querySelector('[data-content-city-picker-ui]');
if (dataTag && dataTag.getAttribute('type') === 'application/json') {
  // При инициализации страницы проверяем отображать предвыбор города или нет
  const exceptionsPath = ['/calculator/'];
  const options = JSON.parse(dataTag.textContent);

  // eslint-disable-next-line max-depth
  if (!exceptionsPath.includes(window.location.pathname)
    && options
    && options.isShowNotify
    && contentCityPickerUI
  ) {
    startLoadCityPicker();
    contentCityPickerUI.classList.add('open');
    document.querySelector('body').classList.add('open-city-picker');
  }
}

const btnCityPickerUI = document.querySelectorAll('[data-btn-city-picker-ui]');
if (btnCityPickerUI && btnCityPickerUI.length) {
  btnCityPickerUI.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.stopPropagation();

      startLoadCityPicker();
      contentCityPickerUI.classList.add('open');
      document.querySelector('body').classList.add('open-city-picker');
    });
  });
}
