import AbstractApiModule from '../../AbstractApiModule';
import { getConvertDataProduct } from '../../../utils/product';
import { getConvertDataPersonalSelections } from '../../../utils/consumer';

const urlFactory = {
  getActiveEntriesPersonal       : '/v2/internal/personal/entries/active/',
  getPreviewSubscriptionPersonal : '/v2/internal/personal/subscription/preview/',
  getOrders                      : '/v2/internal/personal/orders/mono/',
  getOrdersTrial                 : '/v2/internal/personal/orders/trial/',
  cancelOrder                    : (id, type) => `/v2/internal/personal/orders/${type}/detail/id/${id}/cancel/`,
  retryOrder                     : (id) => `/v2/internal/personal/orders/mono/detail/id/${id}/retry/`,
  newAddress                     : (type) => `/v2/internal/personal/delivery_address/${type}/`,
  address                        : (id, type) => `/v2/internal/personal/delivery_address/${type}/${id}/`,
  setDefaultAddress              : (id, type) => `/v2/internal/personal/delivery_address/${type}/${id}/default/`,

  getListDelivery           : '/v1/delivery/mono/list/',
  getDeliveryPoints         : '/v2/internal/personal/delivery_points/',
  getSuggestsDeliveryPoints : '/v2/internal/personal/delivery_points/suggests/',
  getDetailDeliveryPoint    : (code, id) => `/v2/internal/personal/delivery_points/detail/type/${code}/id/${id}/`,

  getHistorySelections        : '/v2/internal/personal/selections/history/',
  getHistoryDepartments       : '/v2/internal/personal/departments/history/',
  cancelActiveEntriesPersonal : (id) => `/v2/internal/personal/entries/detail/id/${id}/cancel/`,
  editActiveEntriesPersonal   : (id) => `/v2/internal/personal/entries/detail/id/${id}/edit/`,
  createReviewDepartments     : (id) => `/v2/internal/personal/departments/detail/id/${id}/review/`,
  createReviewSelections      : (id) => `/v2/internal/personal/selections/detail/id/${id}/review/`,
  getDetailDepartment         : (id) => `/v2/internal/personal/departments/detail/id/${id}/`,

  getSelections : '/v2/internal/personal/selections/',
  getResultsSSG : '/v2/internal/personal/des/test/stats/',

  changePassword              : '/v2/internal/personal/change_password/',
  getPersonalCollections      : '/v2/internal/personal/collections/',
  itemPersonalCollection      : (id) => `/v2/internal/personal/collections/item/${id}/`,
  addPersonalCollection       : '/v2/internal/personal/collections/item/',
  getDetailProductCollections : (id) => `/v2/internal/personal/collections/product/${id}/offers/`,

  getPersonalReminders  : '/v2/internal/personal/reminders/',
  itemPersonalReminders : (id) => `/v2/internal/personal/reminders/item/${id}/`,
  addPersonalReminders  : '/v2/internal/personal/reminders/item/',
  calculateRemindDate   : '/v2/internal/personal/reminders/calculate_remind_date/',

  getPersonalReviews: '/v2/internal/personal/reviews/catalog/',

  getEarnWaysPersonalPoints         : '/v2/internal/personal/points/earn_ways/',
  getLastDepartmentsPersonal        : '/v2/internal/personal/departments/last/',
  getHistoryPersonalPoints          : '/v2/internal/consumer/score/history/',
  getCatalogProductsPersonalReviews : '/v2/internal/personal/reviews/catalog_products/',
  getPointsEarnForPurchase          : '/v2/internal/personal/points/earn_for_purchase/',

  getAdditionalProductsSubscription : '/v2/internal/personal/subscription/products/additional/',
  additionalProductSubscription     : '/v2/internal/personal/subscription/active/orders/current/items/additional/',
  updateLensesSubscription          : '/v2/internal/personal/subscription/active/orders/current/items/lenses/',
  confirmOrderSubscription          : '/v2/internal/personal/subscription/active/orders/current/confirm/'
};

export default class ProfileConsumerModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  // Запись в оптике. Активная запись в оптике
  getActiveEntriesPersonal() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getActiveEntriesPersonal).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. История подборов
  getHistorySelections() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getHistorySelections).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. История оптик
  getHistoryDepartments() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getHistoryDepartments).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. Отмена активной записи
  cancelActiveEntriesPersonal(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.cancelActiveEntriesPersonal(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. редактирование активной записи
  editActiveEntriesPersonal(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.editActiveEntriesPersonal(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. Создание отзыва на оптику
  createReviewDepartments(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.createReviewDepartments(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. Создание отзыва на подбор
  createReviewSelections(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.createReviewSelections(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Запись в оптике. Получение детальной информации об оптике
  getDetailDepartment(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailDepartment(id)).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Подписка. Получение краткой информации
  getPreviewSubscriptionPersonal() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPreviewSubscriptionPersonal).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Получение списка заказов
  getOrders(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getOrders, { params }).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Получение списка заказов пробных линз
  getOrdersTrial(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getOrdersTrial, { params }).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Отмена заказа
  cancelOrder(id, type) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.cancelOrder(id, type)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Повторить заказ
  retryOrder(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.retryOrder(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Установка адресом по умолчанию в профиле
  setDefaultAddress(id, type, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setDefaultAddress(id, type), params).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Удаление адреса из профиля
  deleteAddress(id, type) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.address(id, type)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Добавление адреса в профиле
  addAddress(type, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.newAddress(type), params).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Обновление адреса в профиле
  updateAddress(id, type, params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.address(id, type), params).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Профиль. Получение списка доставок для добавления бонусов
  getListDelivery() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListDelivery).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Профиль. Получение списка точек для пунктов выдачи
  getDeliveryPoints() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDeliveryPoints).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Профиль. Получение подсказок в поиске при добавлении пункта выдачи
  getSuggestsDeliveryPoints(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSuggestsDeliveryPoints, { params }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Профиль. Получение детальной информации для выбранного пункта выдачи
  getDetailDeliveryPoint(code, id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailDeliveryPoint(code, id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Профиль. Изменение пароля
  changePassword(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.changePassword, params).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои подборы. Список подборов
  getSelections() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSelections).then(({ data }) => {
        if (data && data.data) {
          const result = {
            selections      : [],
            recommendations : []
          };

          if (data.data.selections) {
            result.selections = getConvertDataPersonalSelections(data.data.selections);
          }

          if (data.data.recommendations) {
            result.recommendations = data.data.recommendations.length
              ? data.data.recommendations.map((product) => getConvertDataProduct(product))
              : [];
          }

          resolve(result);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои подборы. ССГ. Результаты
  getResultsSSG() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getResultsSSG).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Получение моих предпочтений + список продуктов для выпадающих списков
  getPersonalCollections() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPersonalCollections).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Мои предпочтения. Получение списка торговых предложений продукта
  getDetailProductCollections(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailProductCollections(id)).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Мои предпочтения. Удаление элемента коллекции
  deletePersonalCollection(id) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.itemPersonalCollection(id)).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Мои предпочтения. Добавление элемента коллекции
  addPersonalCollection(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.addPersonalCollection, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Мои предпочтения. Обновление элемента коллекции
  updatePersonalCollection(id, params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.itemPersonalCollection(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Получение напоминаний о покупке
  getPersonalReminders() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPersonalReminders).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Удаление напоминания о покупке
  deletePersonalReminders(id) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.itemPersonalReminders(id)).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Добавление напоминания о покупке
  addPersonalReminders(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.addPersonalReminders, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Обновление напоминания о покупке
  updatePersonalReminders(id, params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.itemPersonalReminders(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Мои продукты Alcon. Расчет даты уведомления
  getCalculateRemindDate(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.calculateRemindDate, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Отзывы
  getPersonalReviews() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPersonalReviews).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Баллы. Список как получить баллы
  getEarnWaysPersonalPoints() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getEarnWaysPersonalPoints).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Баллы. Получить данные об оптике (в последней, в которой проходил подбор)
  getLastDepartmentsPersonal() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getLastDepartmentsPersonal).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Баллы. История баллов МоиГлаза
  getHistoryPersonalPoints(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getHistoryPersonalPoints, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Баллы. Отзыв о товаре. Список продуктов
  getCatalogProductsPersonalReviews() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getCatalogProductsPersonalReviews).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Баллы. Баллы за покупку
  getPointsEarnForPurchase() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPointsEarnForPurchase).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Подписка. Получение доступных доп.товаров
  getAdditionalProductsSubscription() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getAdditionalProductsSubscription).then(({ data }) => {
        const listProducts = [];

        if (data.data && data.data.products && data.data.products.length) {
          data.data.products.forEach((el) => {
            if ((el.sale.eshop && el.sale.eshop.packs && el.sale.eshop.packs.length)
              || (el.sale.mono && el.sale.mono.packs && el.sale.mono.packs.length)
            ) {
              const product = getConvertDataProduct(el);

              if (Array.isArray(product)) {
                // eslint-disable-next-line max-nested-callbacks
                product.forEach((item) => {
                  listProducts.push(item);
                });
              } else {
                listProducts.push(product);
              }
            }
          });
        }

        resolve(listProducts);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Подписка. Добавление доп. товара в подписку
  updateAdditionalProductSubscription(params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.additionalProductSubscription, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Подписка. Удаление доп. товара из подписки
  deleteAdditionalProductSubscription(params) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.additionalProductSubscription, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Подписка. редактирование линз
  updateLensesSubscription(params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.updateLensesSubscription, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Подписка. Подтверждение заказа
  confirmOrderSubscription(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.confirmOrderSubscription, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
