import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getDetailStatisticSelection : '/v2/internal/selection/statistic/period/',
  getListIOL                  : '/v2/internal/personal/orders/mono/',
  getMainParamsIOL            : '/v2/internal/iol/check/',
  confirmAgreementIOL         : '/v2/internal/iol/confirm_agreement/',
  getSpecialistInfo           : '/v2/internal/specialist/info/',
  updatePersonalDataOIL       : '/v2/internal/iol/personal_data/',
  listRequestsOIL             : '/v2/internal/iol/requests/',
  requestOIL                  : (id) => `/v2/internal/iol/requests/${id}/`,
  dataFormRequest             : (id) => `/v2/internal/iol/requests/${id}/edit/`,
  dataDetailRequest           : (id) => `/v2/internal/iol/requests/${id}/detail/`,
  getDictionary               : '/v2/internal/iol/dictionary/',
  addFilesToApplication       : (id, type) => `/v2/internal/iol/requests/${id}/attachments/${type}/files/`,
  deleteFilesToApplication    : (id, type, idFile) => `/v2/internal/iol/requests/${id}/attachments/${type}/files/${idFile}/`,
  publishRequest              : (id) => `/v2/internal/iol/requests/${id}/publish/`,
  requestClarification        : (idRequest, idAnswer) => `/v2/internal/iol/requests/${idRequest}/answers/${idAnswer}/details/`,
  closeRequest                : (id) => `/v2/internal/iol/requests/${id}/close/`,
  copyRequest                 : (id) => `/v2/internal/iol/requests/${id}/copy/`
};

export default class ProfileSpecialistModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getDetailStatisticSelection(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getDetailStatisticSelection, params)
        .then(({ data }) => {
          if (data && data.data) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }

  getListIOL() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.listRequestsOIL).then(({ data }) => {
        resolve(data.data.requests);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getMainParamsIOL() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getMainParamsIOL).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  confirmAgreementIOL() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.confirmAgreementIOL).then(({ data }) => {
        if (data && data.data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getSpecialistInfo(request) {
    if ('memoFetch' in window && !request) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.getSpecialistInfo}`, !request).then(({ data }) => {
          resolve(data);
        }).catch(reject);
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSpecialistInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  // Обновление адреса в профиле
  updatePersonalDataOIL(params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.updatePersonalDataOIL, params).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  createRequest() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.listRequestsOIL).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  /* TODO: Удалить метод после правок бэка */
  checkPublishRequest(id) {
    return new Promise((resolve, reject) => {
      this.get(`/v2/internal/iol/requests/${id}/missing_data/`).then(({ data }) => {
        resolve(data.data.missing_data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  publishRequest(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.publishRequest(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  closeRequest(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.closeRequest(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  copyRequest(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.copyRequest(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getDictionary() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDictionary).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getDataFormRequest(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.dataFormRequest(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getDataDetailRequest(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.dataDetailRequest(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  updateRequest(id, params, config) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.requestOIL(id), params, config).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  deleteRequest(id) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.requestOIL(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  addFilesToApplication(id, type, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.addFilesToApplication(id, type), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  deleteFilesToApplication(idApplication, type, idFile) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.deleteFilesToApplication(idApplication, type, idFile)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  requestClarification(idRequest, idAnswer, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.requestClarification(idRequest, idAnswer), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
