import { parseConfig } from '../../../utils/json-config';

const { isUserAuthorized } = parseConfig();

if (isUserAuthorized) {
  window.addEventListener('appinstalled', () => {
    const url = '/api/v1/events/setPWAInstall/';
    const params = {
      method: 'POST'
    };

    fetch(url, params)
      .catch((e) => {
        console.error('PWA trigger error:', e);
      });
  });
}
