import AbstractApiModule from '../../AbstractApiModule';
import { getAuthConfig } from '../../../utils/getAuthConfig';

const urlFactory = {
  userLogin           : (type) => `/v2/internal/auth/${type}/login/`,
  sendConfirmCode     : (type) => `/v2/internal/auth/${type}/send_confirm_code/`,
  validateConfirmCode : (type) => `/v2/internal/auth/${type}/validate_confirm_code/`,
  register            : (type) => `/v2/internal/auth/${type}/register/`,
  registerShort       : (type) => `/v2/internal/auth/${type}/register_short/`,
  setConsumerActivate : '/v2/internal/auth/consumer/activate/',
  remindPassword      : '/v2/internal/auth/common/remind_password/',
  confirmPassword     : '/v2/internal/auth/common/confirm_password/',
  userLogout          : '/v2/internal/auth/common/logout/',
  getWorkPlaceList    : '/v1/locations/getWorkPlaceList/',
  getSpecs            : '/v1/specialist/getSpecs/'
};

export default class AuthorizationModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  userLogin(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.userLogin(type), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendConfirmCode(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendConfirmCode(type), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  validateConfirmCode(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.validateConfirmCode(type), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  registerUser(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.register(type), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  shortRegisterUser(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.registerShort(type), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setConsumerActivate(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setConsumerActivate, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  remindPassword(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.remindPassword, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setNewPassword(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.confirmPassword, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  userLogout(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.userLogout, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getWorkPlaces() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getWorkPlaceList).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getSpecs() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSpecs).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
