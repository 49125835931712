import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getMonoOrders: '/v1/mono/orders/'
};

export default class OrderModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getMonoOrders() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getMonoOrders).then(({ data }) => {
        resolve(data.data);
      })
        .catch(reject);
    });
  }
}
