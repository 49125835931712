class AccordionFooterMenu {
  constructor(el) {
    this.el = el;
    this.toggler = el.querySelector('[data-btn-accordion-footer-menu]');
    this.toggler.addEventListener('click', () => this.handleToggleClick());
  }

  handleToggleClick() {
    this.el.classList.toggle('open');
  }
}

[...document.querySelectorAll('[data-accordion-footer-menu]')].forEach((el) => new AccordionFooterMenu(el));
