/* eslint-disable */
const logger = (str, url, data = '', debug = false) => {
  if (!debug) {
    return;
  }

  console.warn(str, JSON.parse(JSON.stringify(data)));
};

/*
* @param {string} url - адрес запроса.
* @param {string} use - флаг включения функционала.
* @param {number} cashTime - время кеширования ответов.
* @param {number} requestAttempts - кол-во попыток запросов из кеша если попали в case 2.
* @param {number} checkInterval - частота запросов из кеша.
*
* @description Обертка над fetch для получения данных.
* Кейсы:
* 1) Если один/первый вызов (case 3), получаем данные пишем в кеш на определенное в cashTime время.
* 2) Если второй и последующие вызовы пока не закончился первый (case 2), проверяем кеш с интервалом checkInterval
* с максимальным кол-вом попыток requestAttempts, если неудачно делаем обычный fetch и отдаем рез-т без записи в кеш.
* 3) Если такой запрос уже был и он закончен (case 1), отдаем из кеша.
*/
function memoFetch() {
  const storage = {};
  const process = {};
  const errors = {}

  return function (url, use, cashTime = 60000, requestAttempts = 20, checkInterval = 500) {
    // let intervalId = null; // (interval version)
    let attemptsCounter = 0;
    let id = Math.floor(Math.random() * 100); // * debug

    /*
    * @description таймаут на указанное кол-во ms
    */
    const timeout = ms => new Promise(res => setTimeout(res, ms));

    /*
    * @description очистка полей активный процесс и кеш
    */
    const cleanUp = (url) => {
      delete storage[url];
      delete process[url];
      delete errors[url];
    };

    /*
    * @description очистка полей по таймеру
    */
    const cleanTimer = (cashTime, url) => {
      if (!cashTime) {
        return;
      }
      logger(`(id: ${id}) Setup cleaner ${url}`, url);
      setTimeout(() => {
        logger(`Cache[${url}] - clean!`, url);
        cleanUp(url);
      }, cashTime);
    };

    /*
    * @description рекурсивное получение данных из кеша, и вызов
    * обычного fetch в случае неудачи
    */
    const recursionGetResult = async (storage, url) => {
      if (storage[url]) {
        return storage[url];
      }

      attemptsCounter += 1;

      if (attemptsCounter > requestAttempts) {
        logger(`(id: ${id}) Retry ${url}`, url);
        return await nativeFetch(url);
      } else {
        if (errors[url]) {
          logger(`(id: ${id}) Retry(if error) ${url}`, url);
          return await nativeFetch(url);
        }

        logger(`(id: ${id}) Wait ${url} ${attemptsCounter} out of ${requestAttempts}`, url);
        await timeout(checkInterval);
        return await recursionGetResult(storage, url);
      }
    };

    /*
    * @description запрос с сохранением в кеш
    */
    const doRequest = async (resCallback, rejCallback) => {
      try {
        process[url] = true;
        let resp;
        resp = await fetch(url);
        const data = await resp.json();

        if (data.errors && data.errors.length) {
          throw data;
        }

        storage[url] = data;
        cleanTimer(cashTime, url);
        resCallback(data);
        logger(`(id: ${id}) Case 3: Request done!`, url);
      } catch (e) {
        // clearInterval(intervalId); // (interval version)
        errors[url] = true;
        cleanUp(url);
        rejCallback(e);
        logger(`(id: ${id}) Case 3: Request error!`, url);
      } finally {
        process[url] = false;
      }
    };

    /*
    * @description promise executor
    */
    const promiseHandler = async (res, rej) => {
      logger(`(id: ${id}) Request ${url} start`, url);

      // * (case 1) если данные уже есть - отдаем
      if (storage.hasOwnProperty(url) && storage[url] && !process[url]) {
        res(Object.assign(storage[url], { cache: true }));
        logger(`(id: ${id}) Case 1: Get from cache`, url);
      }

      // * (case 2) если идет процесс получения - ждем
      // ? (interval version) todo native
      // if (process.hasOwnProperty(url) && process[url]) {
      //   intervalId = setInterval(async () => {
      //     if (attemptsCounter > requestAttempts) {
      //       const result = await nativeFetch(url);
      //       res(result);
      //     }
      //     if (storage.hasOwnProperty(url)) {
      //       clearInterval(intervalId);
      //       res(Object.assign(storage[url], { cache: true }));
      //     }
      //   }, checkInterval)
      // }

      // ? (recursive version)
      if (process.hasOwnProperty(url) && process[url]) {
        const result = await recursionGetResult(storage, url, res, rej);

        // * если до того как неудачный запрос упал, был ожидающий вызов,
        // * и по достижению лимита попыток вызов nativeFetch был удачным,
        // * выходим из скрипта без сохранений в кеш, т.к данные получены.
        if (result.hasOwnProperty('native')) {
          logger(`(id: ${id}) Case 2: Request ${url} - done, Get from nativeFetch()`, url);
          res(result);
          return;
        } else {
          res(Object.assign(result, { cache: true }));
          logger(`(id: ${id}) Case 2: Request done, attemptsCounter:${attemptsCounter}, Get from cache!`, url);
        }
      }

      // * (case 3) если процесса и данных нет - получаем
      if (!storage.hasOwnProperty(url) && !process[url]) {
        await doRequest(res, rej);
      }
    };

    /*
    * @description получение данных без сохранения в кеш
    */
    const nativeFetch = async (url) => {
      let data = null;

      try {
        data = await fetch(url);
        data = await data.json();
        data = Object.assign(data, { native: true });
      } catch (e) {
        console.error('memoFetch error in nativeFetch(): ', e)
      }

      return data;
    }

    // * вкл/выкл кеш
    return use
      ? new Promise(promiseHandler)
      : nativeFetch(url);
  }
}

window.memoFetch = memoFetch();
