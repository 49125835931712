import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

window.addEventListener('load', () => {
  const selectorZoomPopup = '[data-popup-ui="popup-mobile-zoom"]';
  const zoomMobile = document.querySelectorAll('.is-mobile-zoom');

  if (zoomMobile && zoomMobile.length) {
    zoomMobile.forEach((el) => {
      // eslint-disable-next-line max-nested-callbacks
      el.addEventListener('click', (event) => {
        if (el.classList.contains('is-tablet') && (window.innerWidth >= 1024)) return;

        event.preventDefault();
        event.stopPropagation();

        const popupUI = document.querySelector(selectorZoomPopup);
        const contentPopup = popupUI ? popupUI.querySelector('[data-content-popup-mobile-zoom]') : null;
        if (popupUI && contentPopup) {
          contentPopup.innerHTML = el.innerHTML;

          popupUI.classList.add('open-popup');
          document.dispatchEvent(new CustomEvent('open-popup'));
          disableBodyScroll(popupUI);
        }
      });
    });
  }

  const closePopupUI = document.querySelectorAll('[data-close-popup-ui]');
  if (closePopupUI && closePopupUI.length) {
    closePopupUI.forEach((btn) => {
      if (btn.closest(selectorZoomPopup)) {
        // eslint-disable-next-line max-nested-callbacks
        btn.addEventListener('click', () => {
          const popupUI = document.querySelector(selectorZoomPopup);
          enableBodyScroll(popupUI);
        });
      }
    });
  }
});
