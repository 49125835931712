import { compare } from './common';

export const PRODUCT_PURCHASE_TYPE = {
  mono: {
    type : 'mono',
    name : 'Упаковка'
  },
  eshop: {
    type : 'eshop',
    name : 'Подписка'
  }
};

export const TYPE_LENSES = {
  multifocal : 'multifocal',
  astigmatic : 'astigmatic',
  sphere     : 'sphere',
  color      : 'color'
};

export const SUBTYPE_LENSES = {
  daily_lenses: {
    name  : 'На день',
    value : 'daily_lenses'
  },
  monthly_lenses: {
    name  : 'На месяц',
    value : 'monthly_lenses'
  },
  astigmatic_lenses: {
    name  : 'Астигматические',
    value : 'astigmatic_lenses'
  },
  multifocal_lenses: {
    name  : 'Мультифокальные',
    value : 'multifocal_lenses'
  },
  sphere_lenses: {
    name  : 'Близорукость/Дальнозоркость',
    value : 'sphere_lenses'
  },
  color_lenses: {
    name  : 'Цветные',
    value : 'color_lenses'
  }
};

export const CATEGORY_CATALOG = {
  all: {
    name       : 'Все продукты',
    nameFilter : 'Все продукты',
    value      : 'all'
  },
  lenses: {
    name       : 'Линзы',
    nameFilter : 'Контактные линзы',
    value      : 'lenses',
    page       : 'kontaktnye-linzy'
  },
  solutions: {
    name       : 'Раствор',
    nameFilter : 'Растворы для линз',
    value      : 'solutions',
    page       : 'rastvory-dlya-linz'
  },
  drops: {
    name       : 'Капли',
    nameFilter : 'Увлажняющие капли',
    value      : 'drops',
    page       : 'kapli-dlya-glaz'
  }
  // sets: {
  //   name       : 'Набор',
  //   nameFilter : 'Наборы',
  //   value      : 'sets'
  // },
  // vitamins: {
  //   icon       : 'product-card-vitamins',
  //   name       : 'Капсулы для здоровья глаз'
  //   nameFilter : 'Капсулы для здоровья глаз'
  // }
};

const getSortedOptions = (list, callback) => {
  if (list && list.length) {
    let options = [...list];

    options.sort((a, b) => (a.sort && b.sort
      ? compare(parseFloat(a.sort), parseFloat(b.sort))
      : () => 0
    ));
    options = options.map(callback);

    return options;
  }

  return [];
};

// Опции для фильтра каталога
// eslint-disable-next-line max-statements
export function getConvertPropertiesFiltersCatalog(properties) {
  const result = {};

  if (properties.addiction) {
    result.addiction = {
      name    : 'Аддидация',
      options : properties.addiction.map((el) => ({ label: el, value: el }))
    };
  }

  if (properties.axis) {
    result.axis = {
      name    : 'Ось',
      options : properties.axis.map((el) => ({ label: el, value: el }))
    };
  }

  if (properties.color) {
    result.color = {
      name     : 'Цвет линз',
      multiple : true,
      options  : properties.color.map((el) => ({ label: `${el.name_ru}/${el.name_en}`, value: el.code, icon: el.picture_url }))
    };
  }

  if (properties.cylinder) {
    result.cylinder = {
      name    : 'Цилиндр',
      options : properties.cylinder.map((el) => ({ label: el, value: el }))
    };
  }

  if (properties.power) {
    result.power = {
      name    : 'Оптическая сила',
      options : properties.power.map((el) => ({ label: el, value: el }))
    };
  }

  if (properties.type) {
    const list = Object.values(properties.type) && Object.values(properties.type).length
      ? Object.values(properties.type)
      : [];

    result.type = {
      name    : 'Тип линз',
      options : getSortedOptions(list, (el) => ({ label: el.name, value: el.code }))
    };
  }

  if (properties.wearing_period) {
    result.wearing_period = {
      name    : 'Период ношения',
      options : properties.wearing_period.map((el) => ({ label: el.name, value: el.code }))
    };
  }

  return result;
}
