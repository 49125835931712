import AbstractApiModule from '../../AbstractApiModule';
import { getConvertPropertiesFiltersCatalog } from '../../../utils/catalog';
import { getConvertDataProduct } from '../../../utils/product';

const urlFactory = {
  getList           : '/v2/internal/catalog/products/',
  getProductsParams : '/v2/internal/catalog/products/params/'
};

export default class CatalogModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getCatalog(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getList, { params }).then(({ data }) => {
        const listProducts = [];

        if (data.data && data.data.products && data.data.products.length) {
          data.data.products.forEach((el) => {
            if ((el.sale.eshop && el.sale.eshop.packs && el.sale.eshop.packs.length)
              || (el.sale.mono && el.sale.mono.packs && el.sale.mono.packs.length)
            ) {
              const product = getConvertDataProduct(el);

              if (Array.isArray(product)) {
                // eslint-disable-next-line max-nested-callbacks
                product.forEach((item) => {
                  listProducts.push(item);
                });
              } else {
                listProducts.push(product);
              }
            }
          });
        }

        resolve({
          products : listProducts,
          shopData : data.data.shopData ? data.data.shopData : null
        });
      }).catch(reject);
    });
  }

  getParamsLensesProducts() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getProductsParams).then(({ data }) => {
        const result = {};

        if (data.data && data.data.map) {
          result.map = data.data.map;
        }
        if (data.data && data.data.properties && Object.keys(data.data.properties).length) {
          result.properties = getConvertPropertiesFiltersCatalog(data.data.properties);
        }

        resolve(result);
      }).catch(reject);
    });
  }
}
