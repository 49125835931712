import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getVideos      : (sort) => `/v2/internal/video_competition/videos/?sort=${sort}`,
  setViewVideo   : (id) => `/v2/internal/video_competition/videos/detail/${id}/view/`,
  setRatingVideo : (id) => `/v2/internal/video_competition/videos/detail/${id}/rating/`
};

export default class VideoCompetitionModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getVideos(sort) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getVideos(sort)).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  setViewVideo(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setViewVideo(id)).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setRatingVideo(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setRatingVideo(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
