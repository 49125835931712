import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const headerUI = document.querySelector('[data-header-ui]');
const btnOpenMenu = document.querySelector('[data-open-menu-ui]');
const btnCloseMenu = document.querySelector('[data-close-menu-ui]');

// Открытие мобильного меню
if (btnOpenMenu) {
  btnOpenMenu.addEventListener('click', (event) => {
    event.preventDefault();

    headerUI.classList.add('open-mobile-menu');
    disableBodyScroll(headerUI);
  });
}

// Закрытие мобильного меню
if (btnCloseMenu) {
  btnCloseMenu.addEventListener('click', (event) => {
    event.preventDefault();

    headerUI.classList.remove('open-mobile-menu');
    enableBodyScroll(headerUI);
  });

  window.addEventListener('hashchange', () => {
    if (headerUI.classList.contains('open-mobile-menu')) {
      headerUI.classList.remove('open-mobile-menu');
      enableBodyScroll(headerUI);
    }
  });
}

// Открываем следующий раздел мобильного меню
const itemMenuUI = document.querySelectorAll('[data-item-menu]');
if (itemMenuUI && itemMenuUI.length) {
  itemMenuUI.forEach((item) => {
    item.addEventListener('click', () => {
      item.classList.add('open');
    });
  });
}

// Селектор по классу, чтобы выбор был только для первого уровня
const titleItemMenuUI = document.querySelectorAll('[data-title-item-menu].item-menu-ui__title');
if (titleItemMenuUI && titleItemMenuUI.length) {
  // Открытие основного пункта меню
  const openDesktopMenu = (itemMenu, headerMenu) => {
    setTimeout(() => {
      if (!itemMenu.classList.contains('blur')) {
        itemMenu.classList.add('active');
        headerMenu.classList.add('active');
      }
    }, 100);
  };

  // Закрытие основного пункта меню
  const closeDesktopMenu = (itemMenu, headerMenu) => {
    headerMenu.classList.remove('active');
    itemMenu.classList.remove('active');

    itemMenu.classList.add('blur');
    setTimeout(() => {
      itemMenu.classList.remove('blur');
    }, 100);
  };

  titleItemMenuUI.forEach((item) => {
    const _itemMenu = item.closest('[data-item-menu]');
    const hasList = _itemMenu.querySelector('[data-list-item-menu]');

    if (_itemMenu && hasList) {
      _itemMenu.addEventListener('mouseenter', (event) => {
        event.stopPropagation();
        openDesktopMenu(_itemMenu, item.closest('[data-header-menu-ui]'));
      });

      _itemMenu.addEventListener('mouseleave', (event) => {
        event.stopPropagation();
        closeDesktopMenu(_itemMenu, item.closest('[data-header-menu-ui]'));
      });
    }
  });
}

// Возврат на предыдущий раздел мобильного меню
const btnBackItemMenuUI = document.querySelectorAll('[data-back-header-menu]');
if (btnBackItemMenuUI && btnBackItemMenuUI.length) {
  btnBackItemMenuUI.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.stopPropagation();

      const itemMenu = item.closest('[data-item-menu]');
      itemMenu.classList.remove('open');
    });
  });
}
