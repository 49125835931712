import { parseConfig } from '../../utils/json-config';

export const getAuthConfig = () => {
  const { sessid } = parseConfig();
  return {
    headers: {
      'X-Bitrix-csrf-token': sessid
    }
  };
};
