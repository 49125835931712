import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getList: '/v2/internal/catalog/products/content/'
};

export default class CatalogMarketingModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getCatalog() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getList).then(({ data }) => {
        resolve(data.data);
      }).catch(reject);
    });
  }
}
