import { compare } from './common';
import { CATEGORY_CATALOG, SUBTYPE_LENSES } from './catalog';

export const TYPE_POPUP_ADD_PRODUCT = {
  replaceSOnS : 'subscription-on-subscription',
  replaceSOnL : 'subscription-on-lenses',
  replaceLOnS : 'lenses-on-subscription',
  default     : 'default'
};

export const EYE_PARAMETERS_PRODUCT = {
  same: {
    label : 'Одинаковые',
    value : 'same'
  },
  different: {
    label : 'Разные',
    value : 'different'
  }
};

export const getAvailableProduct = (packs) => {
  if (!packs || (packs && !packs.eshop && !packs.mono)) return false;

  let result = false;

  Object.keys(packs).forEach((key) => {
    if (packs[key]) {
      if (Array.isArray(packs[key]) && !!packs[key].find((el) => el.isAvailable)) {
        result = true;
      }
      if (!Array.isArray(packs[key]) && packs[key].isAvailable) {
        result = true;
      }
    }
  });

  return result;
};

const getFormattedPrice = (pack) => {
  if (pack.discount && pack.discount.value) {
    return {
      defaultPrice : pack.discount.price.value,
      fullPrice    : pack.price.value,
      discount     : pack.discount.value
    };
  }

  return {
    defaultPrice : pack.price.value,
    fullPrice    : null,
    discount     : null
  };
};

// Итоговая цена
export const getResultPrice = (packs) => {
  if (Array.isArray(packs)) {
    const result = {
      defaultPrice : null,
      fullPrice    : null,
      discount     : null
    };

    packs.forEach((pack) => {
      const objPack = getFormattedPrice(pack);

      Object.keys(objPack).forEach((key) => {
        if (objPack[key]) {
          result[key] = result[key] ? result[key] : 0;
          result[key] += objPack[key];
        }
      });
    });

    return result;
  }

  return getFormattedPrice(packs);
};

// Получение типа попапа, при добавлении товара в корзину
export const getTypePopupAddProductToCart = (basket, typeBasket, purchaseType, productType) => {
  const hasLenses = basket && basket.items && basket.items.length && !!basket.items.find((el) => el.typeProduct === 'lenses');

  // Если в корзине есть подписка
  if (hasLenses && typeBasket === 'eshop') {
    // хотите заменить подписку на подписку
    if (purchaseType === 'eshop') {
      return TYPE_POPUP_ADD_PRODUCT.replaceSOnS;
    }
    // хотите заменить подписку на линзы
    if (productType === 'lenses') {
      return TYPE_POPUP_ADD_PRODUCT.replaceSOnL;
    }
    // открываем попап добавления в корзину
    return TYPE_POPUP_ADD_PRODUCT.default;
  }

  // Если в корзине есть линзы
  if (hasLenses && typeBasket === 'mono') {
    if (purchaseType === 'eshop') {
      // хотите заменить линзы на подписку
      return TYPE_POPUP_ADD_PRODUCT.replaceLOnS;
    }
    // открываем попап добавления в корзину
    return TYPE_POPUP_ADD_PRODUCT.default;
  }

  // открываем попап добавления в корзину
  return TYPE_POPUP_ADD_PRODUCT.default;
};

// Получение из урла offers-params (нужно для перехода в деталку цветных линз)
export const getOfferParamsProduct = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const offerParams = searchParams && searchParams.get('offer_params')
    ? searchParams.get('offer_params')
    : null;

  if (!offerParams) return null;

  const result = offerParams.split(':');
  if (result && result.length && result.length === 2) {
    return {
      name  : result[0],
      value : result[1]
    };
  }

  return null;
};

// Получение урла для картинки
export function getSrcPicture(product, type) {
  if (!product) {
    return null;
  }

  const at1x = product.picture && product.picture[type]
    ? product.picture[type]['1x']
    : null;
  const at2x = product.picture && product.picture[type]
    ? product.picture[type]['2x']
    : null;
  const at3x = product.picture && product.picture[type]
    ? product.picture[type]['3x']
    : null;

  const srcset = [];

  if (at1x) srcset.push(`${at1x} 1x`);
  if (at2x) srcset.push(`${at2x} 2x`);
  if (at3x) srcset.push(`${at3x} 3x`);

  return srcset.join(', ');
}

// Формат для упаковки продукта
const setPackProductInCatalog = (pack, isSeveral) => {
  let label = '';
  if (pack.params.unit.value === 'ml' || pack.params.unit.value === 'мл') { label = `${pack.params.value} мл`; }
  if (pack.params.unit.value === 'lenses' || pack.params.unit.value === 'линз') { label = `${pack.params.value} шт`; }

  const result = {
    idPack      : pack.id,
    isAvailable : pack.is_available,
    value       : pack.id,
    disabled    : !pack.is_available,
    label,
    isSeveral,
    isMinPrice  : pack.is_min_price,
    params      : {
      value : pack.params.value,
      unit  : pack.params.unit.value
    },
    price: {
      value    : pack.price.value,
      currency : pack.price.currency
    },
    points   : pack.points || null,
    quantity : pack.quantity || null
  };

  if (pack.discount) {
    result.discount = {
      type  : pack.discount.type,
      value : pack.discount.value,
      price : {
        value    : pack.discount.price.value,
        currency : pack.discount.price.currency
      }
    };
  }

  return result;
};

// Заголовок для выбора упаковки
export const getLabelForPacks = (unit) => {
  let label = '';
  if (unit === 'ml' || unit === 'мл') { label = 'Объем'; }
  if (unit === 'lenses' || unit === '"линз"') { label = 'Количество линз'; }

  return label;
};

// Получение данных об упаковке продукта
export const getInfoPackInCatalogProducts = (listPacks, filterParameter, properties) => {
  if (!listPacks || (listPacks && !listPacks.length)) return null;

  const sortList = [...listPacks].sort((a, b) => compare(parseFloat(a.params.value), parseFloat(b.params.value)));
  const filteredList = sortList.filter((el) => el[filterParameter]);

  const isSeveral = filteredList && filteredList.length && filteredList.length > 1;

  return {
    isAvailable    : filteredList && filteredList.length > 0,
    packs          : sortList.map((el) => setPackProductInCatalog(el, isSeveral)),
    availablePacks : filteredList && filteredList.length ? filteredList.map((el) => setPackProductInCatalog(el, isSeveral)) : [],
    label          : getLabelForPacks(sortList[0].params.unit.value),
    isMinPrice     : properties ? properties.is_min_price : null
  };
};

// Вспомогательная функция для определения типа продукта
const getActiveType = (listTypes, dictionary) => {
  let result = null;
  const listKeys = Object.keys(dictionary);

  listKeys.forEach((key) => {
    const filteredList = !result
      ? listTypes.filter((type) => type.toLowerCase().includes(dictionary[key].value))
      : null;

    if (filteredList && filteredList.length) {
      result = dictionary[key].value;
    }
  });

  return result;
};

// Получение типа продукта
export const getTypeProduct = (listTypes) => {
  const type = getActiveType(listTypes, CATEGORY_CATALOG);
  let subtype = null;

  if (type === CATEGORY_CATALOG.lenses.value) {
    subtype = getActiveType(listTypes, SUBTYPE_LENSES);
  }

  return { type, subtype };
};

// Основные поля продукта
const getPropertiesProduct = (product, properties) => {
  const newProduct = { ...product };
  const picture = properties.picture.sources ? properties.picture.sources : properties.picture;

  newProduct.picture = {
    default: {
      '1x' : picture.default['1x'],
      '2x' : picture.default['2x'],
      '3x' : picture.default['3x']
    },
    webp: {
      '1x' : picture.webp['1x'],
      '2x' : picture.webp['2x'],
      '3x' : picture.webp['3x']
    }
  };

  newProduct.code = properties.code;
  newProduct.title = properties.title.site_title
    ? properties.title.site_title
    : `${properties.title.brand ? properties.title.brand : ''} ${properties.title.name}`;

  if (properties.sale && properties.sale.eshop && properties.sale.eshop.packs && properties.sale.eshop.packs.length) {
    newProduct.eshop = getInfoPackInCatalogProducts(properties.sale.eshop.packs, 'is_available');
  }

  if (properties.sale && properties.sale.mono && properties.sale.mono.packs && properties.sale.mono.packs.length) {
    newProduct.mono = getInfoPackInCatalogProducts(properties.sale.mono.packs, 'is_available', properties.sale.mono);
  }

  return newProduct;
};

// Доп.карточки в товаре. Нужно пока для вывода цветных линз
const getAdditionalCard = (dataProduct, product, dataAdditionalCard) => {
  const additionalCard = getPropertiesProduct(product, dataAdditionalCard);
  additionalCard.idAdditionalCard = dataAdditionalCard.id;
  additionalCard.gallery = dataAdditionalCard.gallery ? dataAdditionalCard.gallery : dataProduct.gallery;

  if (additionalCard.picture) {
    additionalCard.picture.alt = dataProduct.picture.alt;
  }

  if (dataAdditionalCard.offer_params) {
    additionalCard.offerParams = {
      name  : dataAdditionalCard.offer_params.name,
      value : dataAdditionalCard.offer_params.value
    };
    additionalCard.link = `${dataProduct.link}?offer_params=${dataAdditionalCard.offer_params.name}:${dataAdditionalCard.offer_params.value}`;
  } else {
    additionalCard.link = dataProduct.link;
  }

  return additionalCard;
};

// Преобразование данных продукта, полученных из апи
// eslint-disable-next-line max-statements
export const getConvertDataProduct = (data) => {
  let result = null;
  const typeProduct = getTypeProduct(data.types);

  const product = {
    productId      : data.id,
    description    : data.description,
    offerFields    : data.offer_fields,
    types          : data.types,
    typeProduct    : typeProduct.type,
    subtypeProduct : typeProduct.subtype,
    badges         : data.badges,

    categoryProduct : data.title.category ? data.title.category : '',
    brandProduct    : data.title.brand ? data.title.brand : ''

  };

  if (data.rating) {
    product.rating = {
      avg: data.rating.avg
    };
  }

  // Если у товара есть доп.карточки, то выводим их, а не основной товар
  if (data.additional_cards) {
    result = [];
    product.groupCode = data.code;

    data.additional_cards.forEach((item) => {
      result.push(getAdditionalCard(data, product, item));
    });
  } else {
    product.link = data.link;
    const newProduct = getPropertiesProduct(product, data);
    newProduct.gallery = data.gallery;

    if (newProduct.picture) {
      newProduct.picture.alt = data.picture.alt;
    }
    result = newProduct;
  }

  return result;
};
