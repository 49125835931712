import { sendEventYM, sendParamsYM } from '../../utils/common';

// Клик по кнопке с дата-атрибутом яндекс-метрики
const btnsClickYM = document.querySelectorAll('[data-ym-click]');
if (btnsClickYM && btnsClickYM.length) {
  btnsClickYM.forEach((btn) => {
    btn.addEventListener('click', () => {
      const paramsBtn = btn.getAttribute('data-ym-click-params');
      const params = paramsBtn ? JSON.parse(paramsBtn) : {};

      sendEventYM(btn.getAttribute('data-ym-click'), params);
    });
  });
}

// Клик по кнопке с дата-атрибутом яндекс-метрики (для отправки только параметров)
const btnsClickParamsYM = document.querySelectorAll('[data-ym-send-params]');
if (btnsClickParamsYM && btnsClickParamsYM.length) {
  btnsClickParamsYM.forEach((btn) => {
    btn.addEventListener('click', () => {
      sendParamsYM(btn.getAttribute('data-ym-send-params'));
    });
  });
}
