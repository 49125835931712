import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  uploadCheck       : '/v1/profile/uploadCheck/', // * file
  uploadManualCheck : '/v1/profile/uploadManualCheck/', // * file fallback
  scanCheck         : '/v1/profile/scanCheck/', // * qr
  uploadManualData  : '/v1/profile/uploadManualData/' // * form
};

const configFormData = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default class ReceiptModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  uploadCheck(params, checkSource) {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('bill', params, params.name || '');

      if (checkSource) {
        form.append('checkSource', checkSource);
      }

      this.post(urlFactory.uploadCheck, form, configFormData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }

  uploadManualCheck(params, checkSource) {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('file', params, params.name || '');

      if (checkSource) {
        form.append('checkSource', checkSource);
      }

      this.post(urlFactory.uploadManualCheck, form, configFormData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }

  scanCheck(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.scanCheck, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }

  uploadManualData(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.uploadManualData, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }
}
