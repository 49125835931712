import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getProducts                 : '/v2/internal/moneyback/products/',
  isAvailableRequest          : '/v2/internal/moneyback/requests/is_available/',
  sendFormMoneyback           : '/v2/internal/moneyback/requests/',
  sendFormMoneybackBase       : '/v2/internal/moneyback/applications/',
  sendFormMoneybackRequisites : '/v2/internal/moneyback/applications/requisites/',
  checkPhone                  : '/v2/internal/moneyback/applications/check/'
};

const configFormData = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default class MoneybackModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getProducts() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getProducts).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  isAvailableRequest() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.isAvailableRequest).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendFormMoneyback(form) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendFormMoneyback, form).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendFormMoneybackBase(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendFormMoneybackBase, params, configFormData).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendFormMoneybackRequisites(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendFormMoneybackRequisites, params, configFormData).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  checkPhone(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.checkPhone, params, configFormData).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
