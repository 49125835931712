import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getListCity : '/v1/locations/suggest/city/',
  setCity     : '/v1/locations/set/'
};

export default class SelectCityModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getListCity(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getListCity, params).then(({ data }) => {
        const result = data && data.data ? data.data : [];
        resolve(result);
      }).catch(reject);
    });
  }

  setCity(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setCity, params).then(() => {
        resolve();
      }).catch(reject);
    });
  }
}
