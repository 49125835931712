import AbstractApiModule from '../../AbstractApiModule';
import { getAuthConfig } from '../../../utils/getAuthConfig';

const urlFactory = {
  register          : (type) => `/v2/internal/${type}/consumer/register/`,
  generatePromocode : (type) => `/v2/internal/${type}/promocode/generate/`
};

export default class LandingsModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  /**
   * Регистрация пользователя.
   * @param {string} type - тип регистрации (например, 'landing_t30' тип лендинга).
   * @param {Object} params - параметры регистрации.
   * @param {string} params.phone - номер телефона пользователя.
   * @param {string} params.email - адрес электронной почты пользователя.
   * @param {string} params.password - пароль пользователя.
   * @param {string} params.confirm_password - подтверждение пароля пользователя.
   * @param {string} params.code - код смс.
   * @param {string} params.name - имя пользователя.
   * @param {string} params.confirm_policy - подтверждение согласия с условиями использования. Допустимые значения: 'Y' (да), 'N' (нет).
   * @param {string} params.confirm_policy_18_years_old - подтверждение совершеннолетия. Допустимые значения: 'Y' (да), 'N' (нет).
   * @returns {Promise<Array>} - промис с результатом регистрации.
   */
  register(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.register(type), params, getAuthConfig())
        .then(({ data }) => {
          const result = data && data.data ? data.data : [];
          resolve(result);
        }).catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }

  generatePromocode(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.generatePromocode(type), params, getAuthConfig())
        .then(({ data }) => {
          const result = !!(data.success && data.status === 200);
          resolve(result);
        }).catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }
}
