import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const closePopupUI = document.querySelectorAll('[data-close-popup-ui]');
if (closePopupUI && closePopupUI.length) {
  closePopupUI.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      event.stopPropagation();

      const popupUI = btn.closest('[data-popup-ui]');
      popupUI.classList.remove('open-popup');
      enableBodyScroll(popupUI);

      const id = popupUI.getAttribute('data-popup-ui');
      document.dispatchEvent(new CustomEvent('close-popup', {
        detail: {
          id: id || null
        }
      }));
    });
  });
}

const openPopupUI = document.querySelectorAll('[data-open-popup-ui]');
if (openPopupUI && openPopupUI.length) {
  openPopupUI.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      if (btn.classList.contains('is-tablet') && (window.innerWidth >= 1024)) return;

      event.preventDefault();
      event.stopPropagation();

      const id = btn.getAttribute('data-open-popup-ui');
      const popupUI = document.querySelector(`[data-popup-ui="${id}"]`);
      if (popupUI) {
        popupUI.classList.add('open-popup');
        document.dispatchEvent(new CustomEvent('open-popup'));
        disableBodyScroll(popupUI);
      }
    });
  });
}
