import { getConvertDataProduct } from './product';

export const getConvertDataPersonalSelections = (data) => {
  if (!data || !data.length) {
    return [];
  }

  return data.map((selection) => ({
    selection,
    id      : selection.id,
    product : selection.product ? getConvertDataProduct(selection.product) : null,
    offers  : selection.offers
  }));
};
