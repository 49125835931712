import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  checkAnonSurvey     : '/v1/anon/question/check/',
  sendAnonSurvey      : '/v1/anon/question/answer/',
  sendAnonSurveyEmail : '/v1/anon/question/answer-by-email/'
};

const configFormData = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default class QuestionsModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  checkAnonSurvey(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.checkAnonSurvey, params, configFormData).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendAnonSurvey(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendAnonSurvey, params, configFormData).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendAnonSurveyEmail(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.sendAnonSurveyEmail, params, configFormData).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
