import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  reviews                 : (id) => `/v2/internal/catalog/products/detail/id/${id}/reviews/`,
  getInfoReviewsForPoints : '/v2/internal/loyalty/reviews/products/counter/'
};

export default class ReviewsModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getList(id, params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.reviews(id), { params }).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  sendReview(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.reviews(id), params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getInfoReviewsForPoints() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getInfoReviewsForPoints).then(({ data }) => {
        if (data && data.data) {
          resolve({
            maxCountReviewsForPoints : data.data.maxCountReviewsForPoints,
            countReviews             : data.data.countReviews
          });
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
