/* eslint-disable max-statements */
import '../../utils/memoFetch';
import '../../components/partials/PopupUI';

import './header';
import './cityPicker';
import './menu';
import './footer';

import './anchor-navigation';
import './check-pwa';
import './cookie-prompt';
import './offline-status-bar';
import '../../utils/viewport-dimensions';
import './modal-specialist';
import './metrics';
import './zoom-image';

import { parseConfig } from '../../../utils/json-config';
import { checkGetParam } from '../../utils/checkGetParam';

import '../../../../css/redesign/bundles/r-app-shell/index.scss';

import '../../../../svg-icons/r-logo.svg';
import '../../../../svg-icons/r-user-photo.svg';
import '../../../../svg-icons/r-box.svg';
import '../../../../svg-icons/r-sign-out.svg';
import '../../../../svg-icons/r-favorites-contour.svg';
import '../../../../svg-icons/r-gift.svg';
import '../../../../svg-icons/r-clipboard.svg';
import '../../../../svg-icons/r-iol.svg';
import '../../../../svg-icons/r-promocode.svg';
import '../../../../svg-icons/r-eye-profile.svg';
import '../../../../svg-icons/r-eye-show.svg';
import '../../../../svg-icons/r-calendar-rtl.svg';
import '../../../../svg-icons/r-pin.svg';
import '../../../../svg-icons/r-chat-help.svg';
import '../../../../svg-icons/r-star-rounded.svg';
import '../../../../svg-icons/r-star-contour.svg';
import '../../../../svg-icons/r-history.svg';
import '../../../../svg-icons/r-calendar.svg';
import '../../../../svg-icons/r-settings.svg';
import '../../../../svg-icons/r-bag.svg';
import '../../../../svg-icons/r-hat.svg';
import '../../../../svg-icons/r-ribbon-star.svg';
import '../../../../svg-icons/r-download.svg';
import '../../../../svg-icons/r-payment.svg';
import '../../../../svg-icons/r-bill.svg';
import '../../../../svg-icons/r-water-drop.svg';
import '../../../../svg-icons/r-percent.svg';

import '../../../../svg-icons/r-text.svg';
import '../../../../svg-icons/r-notepad.svg';
import '../../../../svg-icons/r-cart.svg';
import '../../../../svg-icons/r-logo-privilege-club.svg';
import '../../../../svg-icons/r-home.svg';
import '../../../../svg-icons/r-personal.svg';
import '../../../../svg-icons/r-plus.svg';
import '../../../../svg-icons/r-search-filter.svg';
import '../../../../svg-icons/r-search.svg';
import '../../../../svg-icons/r-chat.svg';
import '../../../../svg-icons/r-burger-menu.svg';
import '../../../../svg-icons/r-close-window-rounded.svg';
import '../../../../svg-icons/r-vk.svg';
import '../../../../svg-icons/r-telegram.svg';
import '../../../../svg-icons/r-youtube.svg';

import '../../../../svg-icons/burger-menu.svg';
import '../../../../svg-icons/close-window.svg';
import '../../../../svg-icons/logo.svg';
import '../../../../svg-icons/main-search.svg';
import '../../../../svg-icons/circle-user.svg';
import '../../../../svg-icons/cart.svg';

import '../../../../svg-icons/dropdown-with-icon--location.svg';
import '../../../../svg-icons/dropdown-with-icon--arrow.svg';
import '../../../../svg-icons/dropdown-with-icon--phone.svg';
import '../../../../svg-icons/pdf-lenses.svg';
import '../../../../svg-icons/icon-phone.svg';
import '../../../../svg-icons/10_percent_disc.svg';

// banner live
import '../../../../svg-icons/vk-square.svg';
import '../../../../svg-icons/corner.svg';
import '../../../../svg-icons/player-controls/play.svg';
import '../../../../svg-icons/player-controls/next.svg';
import '../../../../svg-icons/player-controls/pip.svg';
import '../../../../svg-icons/player-controls/settings.svg';
import '../../../../svg-icons/player-controls/volume.svg';
import '../../../../svg-icons/player-controls/full-screan.svg';
import '../../../../svg-icons/player-controls/volume-range.svg';

// banner sisteyn
import '../../../../svg-icons/rounded-check-mark.svg';

const config = parseConfig();

window.addEventListener('load', () => {
  const specialistSegment = document.querySelector('#questionnaire-specialist-segment');
  // Если пользователь - специалист и ему необходимо показать попап опроса по сегментации
  if (specialistSegment && config.isUserAuthorized && config.isUserSpecialist && config.needToFillSpecialistSegment) {
    import(
      /* webpackChunkName: "specialist-segment" */
      '../../components/vue/QuestionnaireSpecialistSegment/index'
    )
      .then((partial) => partial.default(specialistSegment));
  }

  const orderingUnavailablePopupEl = document.querySelector('[data-ordering-unavailable-popup]');
  if (orderingUnavailablePopupEl) {
    import(
      /* webpackChunkName: "ordering-unavailable-popup" */
      '../../../partials/ordering-unavailable-popup/index'
    )
      .then((partial) => partial.default(orderingUnavailablePopupEl));
  }

  const askExpertSpecialist = document.querySelector('#specialist-ask-expert');
  if (askExpertSpecialist) {
    import(
      /* webpackChunkName: "specialist-ask-expert" */
      './specialist-ask-expert'
    )
      .then((partial) => partial.default(askExpertSpecialist));
  }

  const promocodesDisplay = document.querySelector('[data-promocodes-display]');
  if (promocodesDisplay) {
    import(
      /* webpackChunkName: "promocodes-display" */
      './promocodesDisplay'
    )
      .then((partial) => partial.default(promocodesDisplay));

    const appAnchor = document.createElement('div');
    appAnchor.dataset.promocodesBuyPopup = '';
    document.body.appendChild(appAnchor);

    import(
      /* webpackChunkName: "promocodes-buy-popup" */
      './promocodesBuyPopup'
      // eslint-disable-next-line max-nested-callbacks
    ).then((partial) => partial.default(appAnchor));
  }

  const footerFixedMenu = document.querySelector('[data-footer-fixed-menu-ui]');
  if (!footerFixedMenu) {
    document.body.classList.add('no-footer-fixed-menu');
  }
});

(function sendUserIdYM() {
  const sendUserId = ({ data, success }) => {
    if (!success
      // eslint-disable-next-line no-prototype-builtins
      || !data.hasOwnProperty('user')
      || !data.user.phone
      || typeof data.user.phone !== 'string'
    ) {
      return;
    }

    const userPhone = data.user.phone;
    if ('ym' in window && config && config.idYM) {
      window.ym(config.idYM, 'userParams', {
        UserID: userPhone
      });
    }
  };

  if (config.isUserAuthorized) {
    if ('memoFetch' in window) {
      window.memoFetch('/api/v2/internal/consumer/info/', true)
        .then(sendUserId);
    } else {
      fetch('/api/v2/internal/consumer/info/')
        .then((data) => data.json())
        .then(sendUserId);
    }
  }
}());

(function uploadCheckAndAuthFunctional() {
  const uploadWrap = document.querySelector('#upload-modal-wrapper');
  const auth = document.querySelector('#auth');
  const verification = document.querySelector('#call-phone-number-verification');
  let showAuth = false;
  let loadingAuthComponent = false;

  // Загружаем компонент авторизации
  if (auth) {
    import(
      /* webpackChunkName: "auth" */
      '../../components/vue/Auth/index'
    )
      .then((partial) => {
        partial.default(auth);
        loadingAuthComponent = true;

        if (showAuth) {
          document.dispatchEvent(new CustomEvent('auth:show', {
            detail: {
              callback: () => {
                window.location.reload();
              }
            }
          }));
        }
      });
  }

  // Загружаем компонент верификации по звонку
  if (verification) {
    import(
      /* webpackChunkName: "phone-verification-call" */
      '../../components/vue/PhoneVerificationCall'
    )
      .then((partial) => partial.default(verification));
  }

  // Если компонент уже был загружен - открываем попап
  // Если ещё нет, то загружаем компонент, а потом открываем попап
  window.openUploadCheck = () => {
    if (window.CheckUploadButton) {
      window.CheckUploadButton.$children[0].openUploadModal();
    } else {
      import(
        /* webpackChunkName: "upload-product-receipt" */
        '../../components/vue/UploadProductReceipt'
      )
        .then((partial) => {
          partial.default(uploadWrap);
          window.CheckUploadButton.$children[0].openUploadModal();
        });
    }
  };

  function openUploader(e) {
    if (e.target.closest('[data-open-upload-check]')) {
      window.openUploadCheck();
    }
  }

  // Загружаем компонент загрузки чека и обрабатываем клик по атрибуту
  if (uploadWrap) {
    if (checkGetParam('check')) {
      window.openUploadCheck();
    }

    document.body.addEventListener('click', (e) => {
      openUploader(e);
    });
  }

  if (window.location.search.includes('check') && !uploadWrap) {
    if (loadingAuthComponent) {
      document.dispatchEvent(new CustomEvent('auth:show', {
        detail: {
          callback: () => {
            window.location.reload();
          }
        }
      }));
    } else {
      showAuth = true;
    }
  }
}());

const loader = document.querySelector('#loader');
if (loader) {
  document.addEventListener('loader:show', () => {
    loader.classList.add('is-active');
  });

  document.addEventListener('loader:hide', () => {
    loader.classList.remove('is-active');
  });
}

// переход в лк после авторизации
if (!config.isUserAuthorized) {
  const links = document.querySelectorAll('[data-link-club]');

  links.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      if (item.hasAttribute('href')) {
        sessionStorage.setItem('jumpLink', item.getAttribute('href'));
      }
    });
  });
}

if (sessionStorage.getItem('jumpLink')) {
  const jumpLink = sessionStorage.getItem('jumpLink');
  sessionStorage.removeItem('jumpLink');
  document.location.href = jumpLink;
}

window.addEventListener('load', () => {
  // Cart Notification
  const cartNotification = document.querySelector('[data-cart-notification=""]');
  if (cartNotification) {
    import(
      /* webpackChunkName: "cart-notification" */
      '../../../partials/cart-notification/index.js'
    ).then((partial) => partial.default(cartNotification));
  }

  // const emptyEmailPopup = document.querySelector('[data-empty-email-popup]');
  // if (emptyEmailPopup) {
  //   import(
  //     /* webpackChunkName: "empty-email-popup" */
  //     '../../components/partials/empty-email-popup/index.js'
  //   ).then((partial) => partial.default(emptyEmailPopup));
  // }

  const commonInfoPopup = document.querySelector('[data-common-info-popup]');
  if (commonInfoPopup) {
    import(
      /* webpackChunkName: "common-info-popup" */
      '../../components/partials/common-info-popup/index.js'
    ).then((partial) => partial.default(commonInfoPopup));
  }

  // const telegramRewardPopup = document.querySelector('[data-telegram-reward-popup]');
  // if (telegramRewardPopup) {
  //   import(
  //     /* webpackChunkName: "telegram-reward-popup" */
  //     '../../../partials/telegram-reward-popup/index.js'
  //   ).then((partial) => partial.default(telegramRewardPopup));
  // }
});

// TODO: временное закрытие попапа чат-бот
// const SpecialistSnPopup = document.querySelector('[data-specialist-sn-popup]');
// if (SpecialistSnPopup) {
//   import(
//     /* webpackChunkName: "specialist-sn-popup" */
//     '../../../partials/specialist-sn-popup/index.js'
//   ).then((partial) => partial.default(SpecialistSnPopup));
// }

// Universal Email Popup
(() => {
  const emailUniversalSearchParams = new URLSearchParams(window.location.search);
  const val = emailUniversalSearchParams.has('popup');

  if (val) {
    import(
      /* webpackChunkName: "universal-email-popup" */
      '../../../partials/universal-email-popup'
    ).then((partial) => partial.default());
  }
})();

document.addEventListener('DOMContentLoaded', () => {
  const modalEl = document.querySelector('[data-modal="check-add-result"]');
  const searchParams = new URLSearchParams(window.location.search);
  const task = searchParams.has('task') ? searchParams.get('task').trim() : '';
  const token = searchParams.has('token') ? searchParams.get('token').trim() : '';
  const referralCode = searchParams.has('referral_code') ? searchParams.get('referral_code').trim() : '';
  const hasRequiredParams = task && token;
  if (modalEl && hasRequiredParams) {
    import('../../../components/checkModal').then((component) => component.default(modalEl));
  }

  if (referralCode) {
    const div = document.createElement('div');
    div.dataset.referralCodeAnchorEl = '';
    document.body.appendChild(div);
    import('../../../components/ReferralCode').then((component) => component.default(div, referralCode));
  }
});
