import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getConsumerInfo            : '/v2/internal/consumer/info/',
  setPersonalProfile         : '/v2/internal/personal/profile/data/',
  personalLensesInfo         : '/v2/internal/personal/profile/lenses_info/',
  setPersonalEmail           : '/v2/internal/personal/profile/email/',
  getPersonalDeliveryAddress : '/v2/internal/personal/delivery_address/'
};

export default class UserModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getConsumerInfo(request) {
    if ('memoFetch' in window && !request) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.getConsumerInfo}`, !request).then(({ data }) => {
          resolve(data);
        }).catch(reject);
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.getConsumerInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setPersonalProfile(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setPersonalProfile, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getPersonalLensesInfo() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.personalLensesInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setPersonalLensesInfo(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.personalLensesInfo, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setPersonalEmail(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setPersonalEmail, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getPersonalDeliveryAddress() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPersonalDeliveryAddress).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }
}
