class AnchorNavigation {
  constructor(navigation) {
    this.navigation = navigation;
    this.links = [...this.navigation.querySelectorAll('a')];
    this.init();
  }

  init() {
    if (!this.links.length) {
      this.navigation.style.display = 'none';
      return;
    }

    this.deleteUnusedLinks();
    this.handleLinks();
  }

  deleteUnusedLinks() {
    this.links.forEach((link) => {
      const currentAnchor = link.getAttribute('href');
      const currentSection = currentAnchor ? document.querySelector(`${currentAnchor}`) : null;
      if (!currentSection) {
        const currentListItem = link.parentElement;
        currentListItem.parentNode.removeChild(currentListItem);
      }
    });
  }

  handleLinks() {
    this.navigation.addEventListener('click', (e) => {
      const targetLink = e.target.closest('a');

      if (!targetLink) {
        return;
      }

      const targetID = targetLink.getAttribute('href');
      const targetAnchor = document.querySelector(`${targetID}`);

      if (!targetAnchor) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      const targetOffset = +targetLink.getAttribute('data-offset-top') || 0;
      const originalTop = this.distanceToTop(targetAnchor);
      const isDesktop = window.matchMedia('(min-width: 1200px)').matches;
      const headerHeight = document.querySelector('header[data-fixed-header-ui]').clientHeight;
      const mobileOffset = (isDesktop) ? 0 : headerHeight;

      window.scrollBy({
        top      : originalTop - targetOffset - mobileOffset,
        left     : 0,
        behavior : 'smooth'
      });
    });
  }

  distanceToTop(el) {
    return Math.floor(el.getBoundingClientRect().top);
  }
}
window.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('[data-anchor-navigation]')].forEach((navigation) => new AnchorNavigation(navigation));
});
