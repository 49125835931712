import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getPoints         : '/v2/internal/locator/cataract_clinics/',
  getDetailPoint    : (id) => `/v2/internal/locator/cataract_clinics/detail/${id}/`,
  getSuggestsPoints : '/v2/internal/locator/cataract_clinics/suggests/'
};

export default class CataractClinicsModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getPoints(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPoints, { params }).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getDetailPoint(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailPoint(id)).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getSuggestsPoints(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSuggestsPoints, { params }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }
}
